import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// import assets
import Glyph from '../images/glyph.png';
import Logo from '../images/othl-logo-white.svg';

// import helpers
import { getBreakpoint, getColor, getFontWeight } from '../util/theme';

const HeaderWrapper = styled.header`
  border-bottom: 5px solid ${getColor('accentGreen')};

  background-color: ${getColor('primaryBlue')};
  background-image: url(${Glyph});
  background-position: 0 center;
  background-repeat: no-repeat;

  padding: 25px 50px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  @media (min-width: ${getBreakpoint('md')}) {
    flex-direction: row;
  }

  img {
    width: 250px;
    padding: 15px 0;

    &.spacer {
      opacity: 0;
    }
  }

  a {
    color: ${getColor('white')};
    text-align: center;
    text-decoration: none;

    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      color: ${getColor('accentGreen')};
    }

    h1,
    h2 {
      margin: 5px 0 0 0;
    }

    h1 {
      font-size: 3rem;
      font-weight: ${getFontWeight('black')};
      text-shadow: 2px 2px 3px rgba(71, 42, 22, 0.6);
    }

    h2 {
      font-size: 2.5rem;
      font-weight: ${getFontWeight('light')};
      letter-spacing: 0.3rem;
      line-height: 1.3;
    }
  }
`;

const Header = () => (
  <HeaderWrapper role="banner">
    <HeaderContent>
      <a
        href="https://onetrusthomeloans.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={Logo} alt="OneTrust Home Loans" />
      </a>
      <Link to="/">
        <h1>Resource Center</h1>
        <h2>Modular Construction Loans</h2>
      </Link>
      <img className="spacer" alt="Spacer" />
    </HeaderContent>
  </HeaderWrapper>
);

export default Header;
