import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import styled, { ThemeProvider, injectGlobal } from 'styled-components';
import Theme from '../util/theme';

import Header from './Header';
import Footer from './Footer';

const SkipToContentLink = styled.a`
  opacity: 0;

  position: absolute;
  left: 0;

  &:focus {
    opacity: 1;
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <ThemeProvider theme={Theme}>
          <>
            <SkipToContentLink href="#reach-skip-nav">
              Skip to Main Content
            </SkipToContentLink>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div role="main">{children}</div>
            <Footer />
          </>
        </ThemeProvider>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

injectGlobal`
  * {
    box-sizing: border-box;
  }

  html, body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.4;

    margin: 0;
    padding: 0;
  }
`;
