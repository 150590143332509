import React from 'react';
import styled from 'styled-components';

// import assets
import ehlLogo from '../images/ehl.png';
import facebookIcon from '../images/otm_facebook.png';
import linkedInIcon from '../images/otm_linkedin.png';
import twitterIcon from '../images/otm_twitter.png';
import instagramIcon from '../images/otm_instagram.png';

// import theme helpers
import { getBreakpoint, getColor } from '../util/theme';

const FooterWrapper = styled.footer`
  background-color: ${getColor('black')};
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  max-width: 80%;

  margin: 0 auto;
  padding: 25px 0;

  @media (min-width: ${getBreakpoint('xl')}) {
    flex-direction: row;

    height: 150px;
  }
`;

const FooterEhl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  color: ${getColor('white')};

  @media (min-width: ${getBreakpoint('xl')}) {
    flex-direction: row;

    flex-basis: 40%;
  }

  img {
    height: 60px;
    width: 60px;

    margin: 0 15px 0 0;
  }
`;

const FooterAbout = styled.div`
  color: ${getColor('white')};

  @media (min-width: ${getBreakpoint('xl')}) {
    padding: 0 50px;

    flex-basis: 30%;
  }

  a {
    color: ${getColor('white')};

    &:hover {
      color: ${getColor('accentGreen')};
    }
  }
`;

const FooterLinks = styled.div`
  color: ${getColor('white')};

  a {
    color: ${getColor('white')};
    text-decoration: none;

    &:hover {
      color: ${getColor('accentGreen')};
    }
  }
`;

const FooterSocial = styled.div`
  display: flex;

  justify-content: space-evenly;

  img {
    border: 2px solid transparent;
    border-radius: 50%;

    height: 40px;
    width: 40px;

    margin: 0 10px;

    &:hover {
      border: 2px solid ${getColor('accentGreen')};
    }
  }
`;

const Footer = () => (
  <FooterWrapper>
    <FooterContainer>
      <FooterEhl>
        <img src={ehlLogo} alt="Equal Housing Opportunity" />
        <p>
          CalCon Mutual Mortgage LLC, dba OneTrust Home Loans is an Equal
          Housing Lender NMLS #46375; 3131 Camino Del Rio North Suite 1680, San
          Diego, CA 92108. Corporate phone (888) 488-3807.
        </p>
      </FooterEhl>
      <FooterAbout>
        <p>
          <a
            href="https://onetrusthomeloans.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            OneTrust Home Loans
          </a>
          , a dba of Calcon Mutual Mortgage LLC, NMLS #46375 (
          <a
            href="http://nmlsconsumeraccess.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.nmlsconsumeraccess.org
          </a>
          )
        </p>
        <p>
          © 2018 - {new Date().getFullYear()}{' '}
          <a
            href="https://onetrusthomeloans.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            OneTrust Home Loans
          </a>
        </p>
      </FooterAbout>
      <FooterLinks>
        <p>
          <a
            href="https://onetrusthomeloans.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>
        <p>
          <a
            href="https://onetrusthomeloans.com/licensing-information/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Licensing
          </a>
        </p>
      </FooterLinks>
      <FooterSocial>
        <a
          href="https://www.facebook.com/OneTrustHomeLoans/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebookIcon} alt="OneTrust Facebook" />
        </a>
        <a
          href="https://www.linkedin.com/company/onetrust-home-loans"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedInIcon} alt="OneTrust LinkedIn" />
        </a>
        <a
          href="https://www.twitter.com/OneTrustHL"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitterIcon} alt="OneTrust Twitter" />
        </a>
        <a
          href="https://www.instagram.com/onetrusthomeloans/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagramIcon} alt="OneTrust Instagram" />
        </a>
      </FooterSocial>
    </FooterContainer>
  </FooterWrapper>
);

export default Footer;
