const theme = {
  // OneTrust colors, mostly
  colors: {
    primaryBlue: '#2d536f',
    complimentaryBlue: '#5481a3',
    accentGreen: '#99c24d',
    accentOrange: '#eab345',
    accentRed: '#fd5f54',
    black: '#323232',
    white: '#ffffff', // not official OneTrust
    grey: '#e2e2e2', // not official OneTrust
    darkGrey: '#757575', // not official OneTrust,
    lightBlue: '#00bcd4', // not official OneTrust
  },
  // aligns with react-flexbox-grid breakpoints
  // this is designed to be only used on laptop/desktop so mobile isn't as important, but will still be fully responsive
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
  // height breakpoints for MLO laptops that have squishy 1366 x 768 resolution
  heightpoints: {
    mlo: '768px',
  },
  // typography styles - for keeping sizes consistent
  typography: {
    sizes: {
      p: {
        xs: '0.75rem',
        sm: '14px',
        rg: '18px',
        md: '1.15rem',
        lg: '1.5rem',
      },
    },
    // correspond with Google font actual weights for Roboto
    weights: {
      thin: '100',
      light: '300',
      regular: '400',
      medium: '500',
      bold: '700',
      black: '900',
    },
  },
};

// helper functions to ease using theme - components can import just the helpers they need and easily access the theme with them
export const getBreakpoint = breakpoint => props =>
  props.theme.breakpoints[breakpoint];

export const getColor = color => props => props.theme.colors[color];

export const getFontSize = (element, size) => props =>
  props.theme.typography.sizes[element][size];

export const getFontWeight = weight => props =>
  props.theme.typography.weights[weight];

export const getHeightpoint = heightpoint => props =>
  props.theme.heightpoints[heightpoint];

export default theme;
